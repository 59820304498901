@font-face {
  font-family: 'DIN Pro Bold';
  src: url(../fonts/DINPro-Bold.eot);
  src: url(../fonts/DINPro-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/DINPro-Bold.woff2) format("woff2"), url(../fonts/DINPro-Bold.woff) format("woff"), url(../fonts/DINPro-Bold.ttf) format("truetype"), url(../fonts/DINPro-Bold.svg#DINPro-Bold) format("svg"); }

@font-face {
  font-family: 'DIN Pro Italic Bold';
  src: url(../fonts/DINPro-BoldItalic.eot);
  src: url(../fonts/DINPro-BoldItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/DINPro-BoldItalic.woff2) format("woff2"), url(../fonts/DINPro-BoldItalic.woff) format("woff"), url(../fonts/DINPro-BoldItalic.ttf) format("truetype"), url(../fonts/DINPro-BoldItalic.svg#DINPro-BoldItalic) format("svg"); }

@font-face {
  font-family: 'DIN Pro Italic';
  src: url(../fonts/DINPro-Italic.eot);
  src: url(../fonts/DINPro-Italic.eot?#iefix) format("embedded-opentype"), url(../fonts/DINPro-Italic.woff2) format("woff2"), url(../fonts/DINPro-Italic.woff) format("woff"), url(../fonts/DINPro-Italic.ttf) format("truetype"), url(../fonts/DINPro-Italic.svg#DINPro-Italic) format("svg"); }

@font-face {
  font-family: 'DIN Pro';
  src: url(../fonts/DINPro.eot);
  src: url(../fonts/DINPro.eot?#iefix) format("embedded-opentype"), url(../fonts/DINPro.woff2) format("woff2"), url(../fonts/DINPro.woff) format("woff"), url(../fonts/DINPro.ttf) format("truetype"), url(../fonts/DINPro.svg#DINPro) format("svg"); }

body {
  font-family: 'DIN Pro', sans-serif !important; }

nav {
  background: #001B72;
  position: relative;
  padding: 30px 0; }
  nav .logo {
    position: relative;
    z-index: 1; }
  nav ul {
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1; }
    nav ul li {
      list-style: none;
      margin: 0 25px;
      position: relative; }
      nav ul li a {
        color: #FFF;
        font-family: 'DIN Pro Italic';
        text-transform: uppercase;
        font-size: 16px; }
        nav ul li a:hover {
          color: #FFF;
          text-decoration: none; }
      nav ul li:hover::after {
        width: 100%; }
      nav ul li::after {
        content: '';
        transition: all .3s;
        position: absolute;
        width: 0;
        height: 3px;
        background: #FFF;
        bottom: -5px;
        left: 0; }
  nav::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 480px;
    height: 100%;
    background: #00124A;
    transform: matrix3d(2, 0, 0, 0, 0.6, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1); }

footer {
  padding: 75px 0 60px; }
  footer .whatsapp {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 9;
    width: 43px; }
  footer .endereco h5 {
    margin-bottom: 0;
    color: #001B72;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: 'DIN Pro Bold'; }
    footer .endereco h5 label {
      color: #EA002A; }
  footer.contato {
    padding: 115px 0;
    background: #00124A; }
    footer.contato .line {
      width: 80px;
      height: 4px;
      background: #EA002A;
      margin-top: 10px; }
    footer.contato h3 {
      font-family: 'DIN Pro Italic Bold';
      color: #FFF;
      text-transform: uppercase;
      font-size: 1.5rem; }
    footer.contato h5 {
      color: #FFF; }
  footer .owl-nav {
    display: flex;
    justify-content: center; }
    footer .owl-nav button {
      background: transparent !important;
      width: 35px;
      height: 35px;
      border-radius: 25px !important;
      border: 2px #00124A solid !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      transition: all .3s; }
      footer .owl-nav button svg path {
        fill: #00124A;
        transition: all .3s; }
      footer .owl-nav button:hover {
        background: #001B72 !important; }
        footer .owl-nav button:hover svg path {
          fill: #FFF; }

section.grupo {
  padding: 115px 0;
  position: relative;
  display: flex;
  align-items: center; }
  section.grupo label {
    color: #EA002A;
    font-size: 21px;
    text-transform: uppercase;
    font-family: 'DIN Pro Italic'; }
  section.grupo h2 {
    font-size: 2rem;
    color: #001B72;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: 'DIN Pro Italic Bold'; }
  section.grupo button {
    position: relative;
    border: 0;
    background: transparent;
    padding: 13px 25px; }
    section.grupo button span {
      position: relative;
      z-index: 1;
      color: #FFF;
      text-transform: uppercase; }
    section.grupo button::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      background: #EA002A;
      width: 100%;
      height: 100%;
      transform: matrix3d(1, 0, 0, 0, -0.4, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1); }
  section.grupo .image {
    position: absolute;
    right: 0; }

section.form {
  padding: 115px 0;
  position: relative;
  display: flex;
  align-items: center; }
  section.form h2 {
    font-size: 2rem;
    color: #001B72;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: 'DIN Pro Italic Bold'; }
  section.form label {
    color: #001B72;
    font-family: 'DIN Pro Bold'; }
  section.form input, section.form textarea {
    width: 100%;
    border: 1px #A8ABBA solid;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 17px; }
  section.form button {
    position: relative;
    border: 0;
    background: transparent;
    padding: 13px 45px; }
    section.form button span {
      position: relative;
      z-index: 1;
      color: #FFF;
      text-transform: uppercase; }
    section.form button::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      background: #001B72;
      width: 100%;
      height: 100%;
      transform: matrix3d(1, 0, 0, 0, -0.4, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1); }

section.slider-servicos {
  padding: 75px 0;
  background: #00124A; }
  section.slider-servicos h3 {
    font-size: 2rem;
    color: #FFF;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: 'DIN Pro Italic Bold'; }
  section.slider-servicos .owl-carousel {
    margin-top: 35px; }
    section.slider-servicos .owl-carousel .item {
      background: #EA002A;
      transform: matrix3d(1, 0, 0, 0, -0.4, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1);
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center; }
      section.slider-servicos .owl-carousel .item .card {
        padding: 0 29px;
        text-align: left;
        font-family: 'DIN Pro Italic Bold';
        transform: matrix3d(1, 0, 0, 0, 0.4, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1);
        background: transparent;
        border: 0;
        color: #FFF;
        text-transform: uppercase; }
        section.slider-servicos .owl-carousel .item .card h5 {
          margin-bottom: 0;
          display: inherit; }
  section.slider-servicos button {
    position: relative;
    border: 0;
    background: transparent;
    padding: 13px 25px;
    margin-top: 55px; }
    section.slider-servicos button span {
      position: relative;
      z-index: 1;
      color: #FFF;
      text-transform: uppercase; }
    section.slider-servicos button::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      background: #EA002A;
      width: 100%;
      height: 100%;
      transform: matrix3d(1, 0, 0, 0, -0.4, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1); }
  section.slider-servicos .nav-home button {
    background: transparent !important;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1);
    width: 40px;
    height: 40px;
    border-radius: 25px !important;
    border: 2px #FFF solid !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 0;
    transition: all .3s; }
    section.slider-servicos .nav-home button svg path {
      fill: #FFF;
      transition: all .3s; }
    section.slider-servicos .nav-home button::after {
      display: none; }
    section.slider-servicos .nav-home button:hover {
      background: #FFF !important; }
      section.slider-servicos .nav-home button:hover svg path {
        fill: #001B72; }

section.slider-sobre .owl-nav {
  display: flex;
  justify-content: center;
  margin: 45px 0; }
  section.slider-sobre .owl-nav button {
    background: transparent !important;
    width: 35px;
    height: 35px;
    border-radius: 25px !important;
    border: 2px #00124A solid !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: all .3s; }
    section.slider-sobre .owl-nav button svg path {
      fill: #00124A;
      transition: all .3s; }
    section.slider-sobre .owl-nav button:hover {
      background: #00124A !important; }
      section.slider-sobre .owl-nav button:hover svg path {
        fill: #FFF; }

section.cases {
  padding: 75px 0; }
  section.cases .barra {
    transform: matrix3d(1, 0, 0, 0, 0.4, 1, 0, 0, 0, 0, 1, 0, -40, 0, 1, 1);
    background: #EA002A;
    padding: 15px 35px;
    display: flex;
    justify-content: flex-end; }
    section.cases .barra h5 {
      font-family: 'DIN Pro Italic';
      color: #FFF;
      text-transform: uppercase;
      transform: matrix3d(1, 0, 0, 0, -0.4, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1); }
  section.cases .cases {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 0;
    align-items: center;
    justify-content: center; }

section.itens {
  background: #00124A;
  padding: 75px 0; }
  section.itens .line {
    width: 80px;
    height: 4px;
    background: #EA002A;
    margin-top: 10px; }
  section.itens h3 {
    font-family: 'DIN Pro Italic';
    color: #FFF;
    text-transform: uppercase;
    font-size: 1.5rem; }
  section.itens h5 {
    font-family: 'DIN Pro Italic Bold';
    color: #FFF;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-top: 9px; }

section.servicos {
  padding: 115px 0; }
  section.servicos article {
    display: flex;
    align-items: center;
    padding: 130px 0;
    position: relative; }
    section.servicos article label {
      color: #EA002A;
      font-size: 21px;
      text-transform: uppercase;
      font-family: 'DIN Pro Italic Bold'; }
    section.servicos article h2 {
      font-size: 2rem;
      color: #001B72;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-family: 'DIN Pro Italic Bold'; }
    section.servicos article .image {
      position: absolute;
      right: 0;
      height: 440px;
      overflow: hidden; }
      section.servicos article .image img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
    section.servicos article.left .image {
      left: 0 !important;
      right: inherit; }

.remodal {
  max-width: 835px;
  padding: 0;
  line-height: 0; }
  .remodal button {
    background: #FFF;
    border-radius: 50%;
    opacity: .7;
    top: 15px;
    left: 15px; }
  .remodal iframe {
    height: 470px; }

.slider-home {
  position: relative; }
  .slider-home .line {
    height: 80px;
    background: #00124A; }
  .slider-home .item {
    background: #00124A; }
    .slider-home .item .content {
      transform: translateY(-90px); }
      .slider-home .item .content h1 {
        font-family: 'DIN Pro Italic Bold';
        color: #FFF;
        text-transform: uppercase;
        font-size: 2rem; }
      .slider-home .item .content p {
        font-family: 'DIN Pro Italic';
        color: #FFF;
        text-transform: uppercase;
        font-size: 1.7rem;
        line-height: 120%; }
    .slider-home .item.video .content {
      transform: translateY(0);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
    .slider-home .item.video .image {
      position: relative;
      z-index: 9;
      height: 672px;
      display: flex;
      align-content: center;
      justify-content: center; }
      .slider-home .item.video .image img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
      .slider-home .item.video .image .play {
        position: absolute;
        top: 45%; }
        .slider-home .item.video .image .play button {
          background: transparent;
          border: 0;
          transition: all .3s; }
          .slider-home .item.video .image .play button svg {
            width: 55px;
            height: 55px; }
            .slider-home .item.video .image .play button svg path {
              fill: #FFF; }
          .slider-home .item.video .image .play button:hover {
            transform: scale(1.2); }
  .slider-home .nav-home {
    width: 100%;
    position: absolute;
    bottom: 100px;
    z-index: 1;
    right: 0; }
    .slider-home .nav-home button {
      background: transparent !important;
      width: 40px;
      height: 40px;
      border-radius: 25px !important;
      border: 2px #FFF solid !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 5px;
      transition: all .3s; }
      .slider-home .nav-home button svg path {
        fill: #FFF;
        transition: all .3s; }
      .slider-home .nav-home button:hover {
        background: #FFF !important; }
        .slider-home .nav-home button:hover svg path {
          fill: #001B72; }

.banner-top {
  background: #00124A;
  position: relative;
  padding-bottom: 80px; }
  .banner-top .line {
    height: 80px;
    background: #00124A; }
  .banner-top .image {
    height: 450px;
    overflow: hidden; }
    .banner-top .image img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .banner-top .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0; }
    .banner-top .content h1 {
      font-family: 'DIN Pro Italic Bold';
      color: #FFF;
      text-transform: uppercase;
      font-size: 2rem; }

.cta {
  position: relative; }
  .cta .cta-bg {
    position: relative;
    z-index: 1;
    background: url(../img/cta-bg.png);
    background-size: cover;
    padding: 45px 150px; }
    .cta .cta-bg h5 {
      font-family: 'DIN Pro Italic Bold';
      color: #FFF;
      text-transform: uppercase;
      font-size: 1.5rem; }
    .cta .cta-bg p {
      font-family: 'DIN Pro Italic';
      color: #FFF;
      text-transform: uppercase;
      font-size: 1.4rem; }
    .cta .cta-bg button {
      position: relative;
      border: 0;
      background: transparent;
      padding: 13px 25px; }
      .cta .cta-bg button span {
        position: relative;
        z-index: 1;
        color: #FFF;
        text-transform: uppercase; }
      .cta .cta-bg button::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        background: #001B72;
        width: 100%;
        height: 100%;
        transform: matrix3d(1, 0, 0, 0, -0.4, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1); }
  .cta::after {
    display: none;
    content: '';
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0;
    background: #00124A; }
  .cta.home::after {
    display: block; }

.animate::after {
  transition: all .3s; }

.animate:hover::after {
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 5, 1) !important; }

@media (max-width: 767.98px) {
  html, body {
    max-width: 100%;
    overflow-x: hidden; }
  nav .open-menu {
    text-transform: uppercase;
    border: 0;
    width: 40px;
    padding: 0;
    padding-left: 5px;
    background: transparent;
    color: #FFF;
    position: relative;
    line-height: 17px; }
    nav .open-menu::after {
      content: '';
      width: 5px;
      height: 100%;
      background: #EA002A;
      position: absolute;
      top: 0;
      left: 0; }
  nav .menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #001B72;
    z-index: 9;
    padding: 25px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    transform: translateX(-100%);
    transition: all .3s; }
    nav .menu-mobile .close-menu {
      text-transform: uppercase;
      border: 0;
      width: 40px;
      padding: 0;
      padding-left: 20px;
      background: transparent;
      color: #FFF;
      position: absolute;
      top: 30px;
      line-height: 17px; }
      nav .menu-mobile .close-menu::after {
        content: '';
        width: 5px;
        height: 100%;
        background: #EA002A;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(-25deg); }
    nav .menu-mobile.active {
      transform: translateX(0); }
    nav .menu-mobile ul {
      display: flex;
      height: 55%;
      flex-direction: column;
      justify-content: space-around; }
      nav .menu-mobile ul li {
        margin: 0; }
        nav .menu-mobile ul li a {
          font-size: 36px; }
  nav ul {
    display: none; }
  nav::after {
    display: none; }
  .slider-home {
    background: #00124b; }
    .slider-home .line {
      height: 40px; }
    .slider-home .item .content h1 {
      font-size: 1.4rem; }
    .slider-home .item .content p {
      font-size: 1rem; }
    .slider-home .item.video .image {
      height: 550px; }
    .slider-home .item.video .content {
      width: 100%;
      height: 550px; }
      .slider-home .item.video .content video {
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .slider-home .nav-home {
      background: #00124A;
      position: relative;
      margin-top: 25px; }
      .slider-home .nav-home .justify-content-end {
        justify-content: flex-start !important; }
  .remodal iframe {
    height: 225px; }
  section.grupo {
    padding: 50px 0;
    display: block; }
    section.grupo .image {
      position: relative;
      margin-top: 25px;
      display: flex;
      justify-content: flex-end; }
      section.grupo .image img {
        width: 90%; }
    section.grupo .action {
      text-align: center; }
  section.slider-servicos h3 {
    text-align: center; }
  section.slider-servicos .owl-carousel .item .card {
    padding: 0 35px; }
    section.slider-servicos .owl-carousel .item .card h5 {
      font-size: 1rem; }
  section.cases {
    padding: 25px 0; }
    section.cases .barra {
      padding: 20px 95px; }
      section.cases .barra h5 {
        font-size: 1.1rem; }
        section.cases .barra h5 br {
          display: none; }
    section.cases .cases {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr); }
  section.itens {
    padding: 45px 15px; }
    section.itens .icons {
      width: 60%;
      margin-top: 25px; }
      section.itens .icons .mb-4 {
        margin-bottom: 0; }
      section.itens .icons div {
        margin-bottom: 20px !important; }
  section.servicos {
    padding: 50px 0; }
    section.servicos article {
      display: block;
      padding: 50px 0; }
      section.servicos article .image {
        position: relative;
        height: auto;
        display: flex;
        justify-content: flex-end; }
        section.servicos article .image img {
          width: 90%; }
      section.servicos article.left .image {
        justify-content: flex-start; }
  .banner-top {
    padding-bottom: 40px; }
    .banner-top .line {
      height: 40px; }
    .banner-top .image {
      height: 380px; }
    .banner-top .content h1 {
      font-size: 1.4rem; }
  .cta {
    margin-top: 55px; }
    .cta .cta-bg {
      background: url(../img/cta-bgm.png);
      background-repeat: no-repeat;
      padding: 150px 55px;
      background-size: cover; }
    .cta::after {
      display: none !important; }
  footer.contato {
    padding: 80px 0; }
    footer.contato .line {
      margin-bottom: 25px; }
    footer.contato .endereco .mb-5 {
      margin-bottom: 0; }
    footer.contato .endereco div {
      margin-bottom: 25px !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  html, body {
    max-width: 100%;
    overflow-x: hidden; } }

@media (min-width: 1365px) and (max-width: 1599px) {
  nav::after {
    width: 290px; }
  section.slider-servicos .owl-carousel .item .card {
    padding: 0 40px; }
    section.slider-servicos .owl-carousel .item .card h5 {
      font-size: 1rem; }
  section.cases .barra h5 {
    font-size: 1rem; }
  section.servicos article .image {
    height: 400px; }
  .banner-top .content h1 {
    font-size: 1.5rem; }
  .banner-top .image {
    height: 340px; } }

@media (min-width: 1200px) and (max-width: 1360px) {
  .slider-home .item.video .image {
    height: 530px; }
  .slider-home .item.video .content {
    width: 100%;
    height: 530px; }
    .slider-home .item.video .content video {
      object-fit: cover;
      width: 100%;
      height: 100%; } }

@media (min-width: 1361px) and (max-width: 1439px) {
  .slider-home .item.video .image {
    height: 530px; }
  .slider-home .item.video .content {
    width: 100%;
    height: 530px; }
    .slider-home .item.video .content video {
      object-fit: cover;
      width: 100%;
      height: 100%; } }

@media (min-width: 1440px) and (max-width: 1900px) {
  .slider-home .item.video .image {
    height: 580px; }
  .slider-home .item.video .content {
    width: 100%;
    height: 580px; }
    .slider-home .item.video .content video {
      object-fit: cover;
      width: 100%;
      height: 100%; } }
